<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div class="body-container">
      <v-container
        v-if="packageInfo.status && !isLoading"
        class="px-10 px-md-0 px-lg-10"
      >
        <h1 class="text-h1 font-weight-black mb-5">Pagar</h1>
        <div class="mb-10">
          <h2 class="text-h2 font-weight-bold">
            {{ packageInfo.packageName }}
          </h2>
          <div class="d-flex justify-space-between text-h4">
            <div>
              {{ packageInfo.providerName }}
            </div>
          </div>
        </div>
        <!-- Mobile Version -->
        <div v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
          <div class="d-flex justify-center flex-column my-2">
            <div
              v-for="(payment, index) in packageInfo.reservationPayments"
              :key="payment.id"
              class="d-flex justify-center flex-column my-6"
            >
              <div class="d-flex justify-center font-weight-bold text-h4 pb-3">
                {{ index === 0 ? "Anticipo" : `Pago ${index}` }}
              </div>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Fecha límite de pago</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>-</div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Fecha de pago</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>{{ dateFormatter(payment.date) }}</div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Porcentaje</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>{{ getPercent(payment.total) }}%</div>
                </v-col>
              </v-row>
              <v-row class="text-body-1" no-gutters>
                <v-col class="d-flex pr-2 justify-sm-end">
                  <div>Monto</div>
                </v-col>
                <v-col class="d-flex pl-2 justify-end justify-sm-start">
                  <div>{{ numberToCurrency(payment.total) }}</div>
                </v-col>
              </v-row>
            </div>
          </div>

          <div class="d-flex justify-center flex-column my-6">
            <div class="d-flex justify-center font-weight-bold text-h4 pb-3">
              Pendiente por liquidar
            </div>
            <v-row class="text-body-1" no-gutters>
              <v-col class="d-flex pr-2 justify-sm-end">
                <div>Fecha límite de pago</div>
              </v-col>
              <v-col class="d-flex pl-2 justify-end justify-sm-start">
                <div>{{ dateFormatter(packageInfo.paymentDeadline) }}</div>
              </v-col>
            </v-row>
            <v-row class="text-body-1" no-gutters>
              <v-col class="d-flex pr-2 justify-sm-end">
                <div>Fecha de pago</div>
              </v-col>
              <v-col class="d-flex pl-2 justify-end justify-sm-start">
                <div>-</div>
              </v-col>
            </v-row>
            <v-row class="text-body-1" no-gutters>
              <v-col class="d-flex pr-2 justify-sm-end">
                <div>Porcentaje</div>
              </v-col>
              <v-col class="d-flex pl-2 justify-end justify-sm-start">
                <div>{{ getPercent(getAmountToSettle()) }}%</div>
              </v-col>
            </v-row>
            <v-row class="text-body-1" no-gutters>
              <v-col class="d-flex pr-2 justify-sm-end">
                <div>Monto</div>
              </v-col>
              <v-col class="d-flex pl-2 justify-end justify-sm-start">
                <div>{{ numberToCurrency(getAmountToSettle()) }}</div>
              </v-col>
            </v-row>
            <v-row class="text-body-1" no-gutters>
              <v-col class="d-flex pr-2 justify-sm-end">
                <div class="font-weight-bold">Total</div>
              </v-col>
              <v-col class="d-flex pl-2 justify-end justify-sm-start">
                <div class="font-weight-bold">
                  {{ numberToCurrency(packageInfo.totalPayment) }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- Desktop Version -->
        <div v-else>
          <v-row>
            <v-col cols="2" />
            <v-col class="border-divider" cols="3">
              <div
                class="d-flex justify-center text-center text-h3"
                style="line-height: normal"
              >
                Fecha límite de pago
              </div>
            </v-col>
            <v-col class="border-divider" cols="3" lg="2">
              <div
                class="d-flex justify-center text-center text-h3"
                style="line-height: normal"
              >
                Fecha de pago
              </div>
            </v-col>
            <v-col class="border-divider" cols="1">
              <div
                class="d-flex justify-center text-center text-h3"
                style="line-height: normal"
              >
                Porcentaje
              </div>
            </v-col>
            <v-col class="border-divider" cols="2">
              <div
                class="d-flex justify-center text-center text-h3"
                style="line-height: normal"
              >
                Monto
              </div>
            </v-col>
            <v-col class="border-divider" cols="1" lg="2">
              <div
                class="d-flex justify-center text-center text-h3"
                style="line-height: normal"
              >
                Total
              </div>
            </v-col>
          </v-row>
          <div
            v-for="(payment, index) in packageInfo.reservationPayments"
            :key="payment.id"
            class="my-2 text-h4"
          >
            <v-row>
              <v-col cols="2">
                <div class="font-weight-bold">
                  {{ index === 0 ? "Anticipo" : `Pago ${index}` }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex justify-center">-</div>
              </v-col>
              <v-col cols="3" lg="2">
                <div class="d-flex justify-center">
                  {{ dateFormatter(payment.date) }}
                </div>
              </v-col>
              <v-col cols="1">
                <div class="d-flex justify-center">
                  {{ getPercent(payment.total) }}%
                </div>
              </v-col>
              <v-col cols="2">
                <div class="d-flex justify-center">
                  {{ numberToCurrency(payment.total) }}
                </div>
              </v-col>
              <v-col cols="1" lg="2">
                <div class="d-flex justify-center">-</div>
              </v-col>
            </v-row>
          </div>
          <div class="my-2 text-h4">
            <v-row>
              <v-col cols="2">
                <div class="font-weight-bold">Pendiente por liquidar</div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex justify-center">
                  {{ dateFormatter(packageInfo.paymentDeadline) }}
                </div>
              </v-col>
              <v-col cols="3" lg="2">
                <div class="d-flex justify-center">-</div>
              </v-col>
              <v-col cols="1">
                <div class="d-flex justify-center">
                  {{ getPercent(getAmountToSettle()) }}%
                </div>
              </v-col>
              <v-col cols="2">
                <div class="d-flex justify-center">
                  {{ numberToCurrency(getAmountToSettle()) }}
                </div>
              </v-col>
              <v-col cols="1" lg="2">
                <div class="d-flex justify-center">
                  {{ numberToCurrency(packageInfo.totalPayment) }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div
          v-if="packageInfo.status !== 'paid'"
          class="mt-16 text-center text-sm-start"
        >
          <div class="text-h3 font-weight-bold mb-4">Realizar pago</div>
          <div
            class="d-flex align-center justify-center justify-sm-start mb-7 text-h4"
          >
            <template>
              <span class="black--text mr-5">Pago con tarjeta</span>
              <v-img
                max-height="40px"
                max-width="125px"
                src="@/assets/icons/CardsPayment.svg"
              />
            </template>
          </div>
          <v-form ref="reservationPaymentForm" v-model="valid" lazy-validation>
            <div
              class="d-flex flex-column flex-md-row align-center align-sm-start align-md-baseline text-h4"
            >
              <span class="mb-2">
                Realizar pago por la siguiente cantidad
              </span>
              <div class="d-flex align-baseline flex-wrap flex-sm-nowrap">
                <div
                  :style="
                    $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 166px'
                  "
                  class="mr-0 mr-sm-2 mx-md-6 mt-2 mt-md-0 mb-3 mb-sm-0"
                >
                  <v-text-field
                    v-model.number="paymentAmount"
                    flat
                    dense
                    solo
                    outlined
                    type="number"
                    pattern="^[0-9]+"
                    min="0"
                    step="1"
                    :height="
                      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 47
                    "
                    color="secondary"
                    class="rounded-lg v-input--is-focused"
                    placeholder="0.00"
                    :rules="moneyRules"
                    :hide-details="$vuetify.breakpoint.xs && 'auto'"
                    oninput="if(this.value < 0) this.value = '';"
                    @keypress.enter.prevent="handleReservationPayment"
                    @input="handleQuantityFormat"
                  >
                    <template slot="prepend-inner">
                      <span
                        class="mr-n1 black--text"
                        :class="!paymentAmount && 'text--disabled'"
                      >
                        $
                      </span>
                    </template>
                  </v-text-field>
                </div>
                <Button
                  text="Pagar"
                  :block="$vuetify.breakpoint.xs"
                  :disabled="isPaymentDisabled"
                  aria-label="Realizar pago"
                  @event="handleReservationPayment()"
                />
              </div>
            </div>
            <transition-group
              name="fade"
              tag="div"
              class="mt-2 mt-sm-0 mb-2 mb-sm-5 red--text"
            >
              <div
                v-if="paymentAmount * 100 < 1500 && paymentAmount !== null"
                key="min-payment-error"
                class="font-italic"
              >
                El monto mínimo para un pago es de $15.00. Consulta más detalles
                en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                >
                  este enlace.
                </router-link>
              </div>
              <div
                v-if="paymentAmount * 100 > getAmountToSettle()"
                key="exceed-payment-error"
                class="font-italic"
              >
                El monto a pagar debe ser menor o igual a la cantidad pendiente
                por liquidar
              </div>
              <div
                v-if="handleResidual()"
                key="residual-error"
                class="font-italic"
              >
                El monto restante después del pago debe ser mayor a $14.99.
                Consulta más detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                >
                  este enlace.
                </router-link>
              </div>
              <div
                v-if="handleMaxPayment()"
                key="max-payment-error"
                class="font-italic"
              >
                El monto máximo para un pago es de $999,999.99. Consulta más
                detalles en
                <router-link
                  style="color: inherit"
                  :to="{ name: 'TermsOfServiceUser' }"
                  target="_blank"
                >
                  este enlace.
                </router-link>
              </div>
            </transition-group>
          </v-form>
          <div
            class="d-flex flex-column flex-sm-row align-center mb-3 mb-sm-9 pt-5"
          >
            <v-card
              elevation="0"
              height="73px"
              class="d-flex align-center px-5 mr-0 mr-sm-5 mt-8 mt-sm-0 rounded-lg blue-border order-2 order-sm-1"
            >
              Pago gestionado por
              <img
                :width="$vuetify.breakpoint.xs ? '60px' : '67px'"
                style="pointer-events: none"
                src="@/assets/icons/providers/StripeLogo.svg"
              />
            </v-card>
          </div>
          <div class="text-h4">
            Conoce más sobre
            <a
              href="https://stripe.com/es/payments"
              target="_blank"
              rel="noopener noreferrer"
              class="font-weight-bold"
              style="color: inherit"
            >
              Stripe
            </a>
          </div>
        </div>
      </v-container>
      <v-container
        v-else-if="isLoading"
        class="d-flex flex-column align-div-content align-center"
      >
        <v-progress-circular indeterminate color="secondary" :size="100" />
      </v-container>
    </div>
    <ModalLoading :dialog="loadingPayment" />
    <ModalPaymentDeclined
      :dialog="this.$route.query.transaccion_incompleta == 'true'"
      is-package
      @closeModal="handlePaymentDeclinedModal"
    />
    <div class="pt-16">
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Button from "@/components/Shared/Button.vue";
import Footer from "@/components/Shared/Footer.vue";
import ModalPaymentDeclined from "@/components/User/Payments/ModalPaymentDeclined.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";
import { mapState } from "vuex";
import { moneyRules } from "@/Utils/rules.js";
import { useQuery } from "@/graphql/index";
import { GET_PACKAGE_RESERVATION } from "@/graphql/queries";
import { useMutation } from "@/graphql/index";
import {
  CREATE_PACKAGE_RESERVATION_PAYMENT,
  CREATE_CHECKOUT_SESSION,
} from "@/graphql/mutations";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  name: "Payment",
  components: {
    Navbar,
    UserNavbar,
    SearchBar,
    Button,
    Footer,
    ModalPaymentDeclined,
    ModalLoading,
  },
  data() {
    return {
      packageInfo: {},
      isLoading: false,
      loadingPayment: false,
      paymentFetching: false,
      paymentFetched: false,
      valid: null,
      paymentAmount: null,
      moneyRules: moneyRules,
    };
  },
  computed: {
    ...mapState({
      user(state) {
        return state.user;
      },
    }),
    isPaymentDisabled() {
      const paymentAmountCents = this.paymentAmount * 100;
      return (
        !this.handleMinPayment() ||
        this.handleMaxPayment() ||
        this.handleResidual() ||
        paymentAmountCents > this.getAmountToSettle()
      );
    },
  },
  watch: {
    user: {
      async handler(user) {
        if (!user || !user.id) return;
        if (!this.paymentFetching && !this.paymentFetched) {
          this.paymentFetching = true;
          await this.fetchPayment();
          this.paymentFetched = true;
          this.paymentFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchPayment() {
      this.isLoading = true;
      const props = {
        packageReservationId: parseInt(this.$route.params.id),
        clientId: parseInt(this.user.id),
        route: "user",
      };

      try {
        const { data, errors } = await useQuery(GET_PACKAGE_RESERVATION, props);
        if (errors) throw errors;
        this.packageInfo = data.packageReservation;
        this.packageInfo.status !== "confirmed" &&
          this.$router.push({ name: "UserPayments" });
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    dateFormatter(unixTime) {
      const date = new Date(unixTime * 1000);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    getAmountToSettle() {
      const totalPayment = this.packageInfo.totalPayment || 0;
      const totalPaid = this.packageInfo.reservationPayments.reduce(
        (sum, payment) => sum + payment.total,
        0
      );
      return totalPayment - totalPaid;
    },
    getPercent(amount) {
      if (
        !this.packageInfo.totalPayment ||
        this.packageInfo.totalPayment === 0
      ) {
        return 0;
      }
      return ((amount / this.packageInfo.totalPayment) * 100).toFixed(2);
    },
    numberToCurrency(number) {
      return numberToCurrency(number / 100);
    },
    handleQuantityFormat() {
      if (typeof this.paymentAmount !== "number") {
        this.paymentAmount = parseFloat(this.paymentAmount) || 0;
      }
      const formattedQuantity = this.paymentAmount.toFixed(2);
      this.paymentAmount = parseFloat(formattedQuantity);
    },
    handleMaxPayment() {
      return this.paymentAmount * 100 >= 99999999;
    },
    handleMinPayment() {
      return this.paymentAmount * 100 >= 1500;
    },
    handleResidual() {
      const remainingAmount =
        this.getAmountToSettle() - this.paymentAmount * 100;
      return remainingAmount > 0 && remainingAmount < 1500;
    },
    handlePaymentDeclinedModal() {
      this.$router.push({
        name: "UserPackagePayment",
        params: { id: this.$route.params.id },
      });
    },
    async handleReservationPayment() {
      this.loadingPayment = true;
      const paymentInfo = {
        packageReservationId: parseInt(this.$route.params.id),
        total: this.paymentAmount * 100,
      };
      const { data, errors } = await useMutation(
        CREATE_PACKAGE_RESERVATION_PAYMENT,
        paymentInfo
      );
      if (data) {
        const checkoutSession = {
          cancelUrl: `${process.env.VUE_APP_STRIPE_URL}usuario/pagos/paquetes/reservacion/${paymentInfo.packageReservationId}?transaccion_incompleta=true`,
          successUrl:
            `${process.env.VUE_APP_STRIPE_URL}usuario/pagos/paquetes/` +
            (this.getAmountToSettle() - this.paymentAmount * 100 > 0
              ? "abono_exitoso"
              : "liquidacion_exitosa"),
          customerEmail: this.user.email,
          paymentId: parseInt(data.createPackageReservationPayment.id),
          paymentMethod: "card",
        };
        const { data: checkoutSessionData, errors: e } = await useMutation(
          CREATE_CHECKOUT_SESSION,
          checkoutSession
        );
        if (checkoutSessionData) {
          window.location.replace(
            checkoutSessionData.createCheckoutSessionPackageReservationPayment
              .url
          );
        } else if (e) {
          console.log(e);
        }
      } else if (errors) {
        console.log(errors);
      }

      this.loadingPayment = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.blue-border {
  border: 1px solid var(--v-secondary-base);
}
.border-divider {
  border-bottom: 1px solid #21145f;
}
.align-div-content {
  display: flex;
  justify-content: center;
  height: 40vh;
}
</style>
