<template>
  <div v-if="dialog">
    <v-dialog v-model="dialog" persistent max-width="815px">
      <v-card class="px-10 py-8">
        <div class="px-md-6">
          <v-card-title class="mt-2 pa-0 d-flex justify-center">
            <div
              class="font-weight-bold text-h3 text-md-h2 text-center"
              style="word-break: break-word"
            >
              Transacción incompleta
            </div>
            <v-btn
              icon
              :ripple="false"
              absolute
              right
              top
              class="btn-background-transparent hover-close-btn"
              @click="closeModal"
            >
              <v-icon large color="secondary">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>

        <div class="d-flex justify-center">
          <div class="d-flex flex-column align-center">
            <div class="my-4 my-sm-8 text-body-1 text-sm-h4 text-center">
              La transacción no se pudo completar, intente de nuevo.
            </div>
          </div>
        </div>

        <div>
          <v-row class="pb-4 px-0 ma-0">
            <v-col
              cols="12"
              class="d-flex flex-column align-center pb-1 pb-sm-2 px-0 mx-0"
            >
              <v-img
                :src="require(`@/assets/icons/CircleCrossRed.svg`)"
                alt
                contain
                class="icon"
                width="50px"
              />
            </v-col>
            <v-col cols="12" class="text-center pt-0 px-0 mx-0">
              <div class="text-h4 font-weight-bold" style="line-height: normal">
                Transacción incompleta
              </div>
            </v-col>
          </v-row>
        </div>

        <div class="d-flex justify-center">
          <div class="d-flex flex-column align-center">
            <div>
              <Button
                text="Reintentar pago"
                aria-label="Reintentar pago"
                @event="closeModal()"
              />
            </div>
            <div class="mt-5">
              <a
                class="text-h4 font-weight-bold text-decoration-underline"
                style="color: inherit"
                @click="handleRequestChange()"
              >
                Cancelar
              </a>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ModalPaymentDeclined",
  components: {
    Button,
  },
  props: {
    dialog: { type: Boolean, required: true },
    isPackage: { type: Boolean, default: false },
  },
  data: () => ({
    statusText: "Transacción incompleta",
    statusStep: "Reservación pendiente",
    statusIcon: "CrossCircleRed",
    stepIcon: "CrossCircle",
  }),
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    handleRequestChange() {
      this.isPackage
        ? this.$router.push({ name: "UserReservations" })
        : this.$router.push({ name: "UserRequests" });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
